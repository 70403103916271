<template>
  <div>
    <div class="form-check form-switch m-0">
      <input
        @input="setValue($event)"
        :checked="rawValue"
        class="form-check-input"
        type="checkbox"
        id="editor-exercise-lookup"
      >
      <label class="form-check-label" for="editor-exercise-lookup">
        Only search exercises from my own library
      </label>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ExerciseLookupConfig',
  computed: {
    ...mapState('config', ['configUIEditorExerciseLookup']),
    rawValue() {
      const { configUIEditorExerciseLookup } = this;
      if (typeof configUIEditorExerciseLookup === 'boolean') {
        return configUIEditorExerciseLookup;
      }
      return configUIEditorExerciseLookup === 'true';
    },
  },
  methods: {
    setValue(e) {
      const value = e.target.checked;
      this.$store.dispatch('config/setUIEditorExerciseLookup', {
        vm: this,
        value,
      });
    },
  },
};
</script>
